import React, {useState, useEffect,} from 'react'
import { graphql, Script } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation, } from "framer-motion"
import { kebabCase } from 'lodash'
import Layout from "../components/layout"
import Seo from "../components/seo"
import SideBar from "../components/sidebar"
import SidebarIcon from "../components/sidebaricon"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import * as styles from '../styles/blog-post.module.scss'
import "../styles/blogpost.css"
import { createCategoryNameMap, createLinkStyle } from '../components/functions/createconst'
import SnsPostShare from '../components/snspostshare'
import AdSense from '../components/blogparts/adsense'
import TagsList from '../components/tagslist'
import RelatedPosts from '../components/relatedposts'
import PageBottomAffili from '../components/blogparts/pagebottomaffili'
import LineStampLink from '../components/blogparts/linestamplink'
import PopularList from '../components/blogparts/popularlist'
import LineAdsLink from '../components/blogparts/lineadslink'
import ABtest from '../components/blogparts/abtest'
import AffiliOutdoor from '../components/blogparts/affilioutdoor'
import AffiliLiq from '../components/blogparts/affililiq'
import AffiliDrink from '../components/blogparts/affilidrink'
import AffiliTravel from '../components/blogparts/affilitravel'
import YoutubeLinkBanner from '../components/blogparts/youtubeadslink'

const BlogPostTemplate = ({ data, location }) => {

  const categorykeymap = createCategoryNameMap()  
  const post = data.mdx
  const LinkStyle = createLinkStyle()
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const relatedposts = data.relatedposts
  const { previous, next } = data
  const eyecatch = getImage(data.mdx.frontmatter.hero)
  const ogpimage = data.mdx.frontmatter.hero.childImageSharp.fluid.src
  const breadcrumb1link = `/${kebabCase(post.frontmatter.category1)}`
  const breadcrumb2link = `/${kebabCase(post.frontmatter.category1)}/${kebabCase(post.frontmatter.category2)}`

  let articleurl
  if(typeof window !== "undefined"){
    articleurl = window.location.href
  }

  const tocVariants = {
    hidden: {
      scale : 0,

    },
    visible: {
      scale : 1,
    }
  }

  const [isOpen, setOpen] = useState(false);

  const control = useAnimation();

  useEffect(()=>{
      if(!isOpen){
          control.start("hidden");
      }
      else if(isOpen){
          control.start("visible");
      }

  }
  )

  let previousslug;
  let nextslug;
  let previouseyecatch;
  let nexteyecatch;

  if(previous){
    previousslug = `/${kebabCase(previous.frontmatter?.category1)}/${kebabCase(previous.frontmatter?.category2)}${previous.fields?.slug}`
    previouseyecatch = getImage(previous.frontmatter?.hero);
  }
  if(next){
    nextslug = `/${kebabCase(next.frontmatter?.category1)}/${kebabCase(next.frontmatter?.category2)}${next.fields?.slug}`
    nexteyecatch = getImage(next.frontmatter?.hero);
  }

  const breadcrumb = [categorykeymap[post.frontmatter.category1], categorykeymap[post.frontmatter.category2], post.frontmatter.title]

  return (
    <Layout location={location} title={siteTitle}>
      <div className = {styles.mainwrapper}>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          ogpimage = {ogpimage} 
        />

        <div className={styles.sidebaricon}
          onClick = {() => setOpen(!isOpen)}
        >
          <SidebarIcon
            isOpen = {isOpen}
          ></SidebarIcon>
        </div>


        <div className ={styles.blogpostwrapper}>
          <article
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
          >

          <div className = {styles.breadcrumb}>
            <span className = {styles.marker}>
              <a href = {breadcrumb1link} className = {styles.breadcrumblink} style = {LinkStyle} >
                {breadcrumb[0]}
              </a>
            </span>
            <span className = {styles.marker}>
              <a href = {breadcrumb2link} className = {styles.breadcrumblink} style = {LinkStyle} >
                {breadcrumb[1]}
              </a>
            </span>
            <div className ={styles.breadcrumbslug}>
              {breadcrumb[2]}
            </div>
          </div>

            <div className = {isOpen ? styles.flexcontentswrapper : styles.contentswrapper}>
              

              <div className = {styles.maincontents}>

                <h1 itemProp="headline">{post.frontmatter.title}</h1>
                
                <div className = {styles.eyecatchwrapper}>
                  <GatsbyImage className = {styles.eyecatch}
                    image = {eyecatch}
                    alt = "eyecatch img"
                    width = {1024}
                  />
                </div>

                <p className = {styles.postdate}>{post.frontmatter.date}</p>
                
                <div className = {styles.pr}>
                  PR
                </div>


                <AdSense path = {location.pathname}></AdSense>

                <YoutubeLinkBanner/>

                <LineAdsLink />

                <MDXRenderer
                  frontmatter={post.frontmatter}
                  itemProp="articleBody"
                  localImagesL = {post.frontmatter.embeddedImagesLocalL}
                  localImagesP = {post.frontmatter.embeddedImagesLocalP}
                  path = {location.pathname}
                >
                  {post.body}
                </MDXRenderer>

                <h2>おまけ</h2>

                <p>このブログはアフィリエイトに参加しているのですが、アフィリエイトの広告って、結構面白いものがあるんですよね。</p>

                <p>中には<b className={styles.bmb}>「なにコレ！欲しい！」と思うようなものもあります。</b>結構アフィリエイトの広告って眺めているだけでも面白いです。</p>

                <p>私は結構多趣味で<b>パソコン関係からスポーツ、アウトドア、料理…色々やります。</b>そんな私が、有名なASPサイトである「A8.net」と「もしもアフィリエイト」の広告を<b className={styles.bmb}>全て</b>見て「これは面白いな」と思ったものや、実際に利用しているサービスを紹介していきたいと思います！</p>

                <ABtest
                  ratio = {50}
                  a = {<AffiliOutdoor/>}
                  b = {<AffiliLiq/>}
                />
                <ABtest
                  ratio = {50}
                  a = {<AffiliDrink/>}
                  b = {<AffiliTravel/>}
                />

                <LineStampLink/>

                <SnsPostShare 
                  articleUrl={articleurl} 
                  articleTitle={post.frontmatter.title}
                ></SnsPostShare>

                <PopularList slugs = {["nakatsu-hitomehakkei/", "hita-ooyamadamu/", "others-mirrorshield/"]} />

                <PageBottomAffili></PageBottomAffili>

                <AdSense path = {location.pathname}></AdSense>

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6562620686181061"
                  crossorigin="anonymous">
                </script>



              </div>

              <motion.div className={isOpen ? styles.sidebarwrapper : styles.hiddensidebarwrapper}
                animate = {control}
                initial = "hidden"
                variants = {tocVariants}
              >
                <div className = {styles.stickysidebar}>
                  <SideBar
                  data = {data}
                  ></SideBar>
                </div>
              </motion.div>
              
            </div>
          </article>



          <div className={styles.relatedpostswrapper}>
            <div className={styles.relatedpost}>関連記事</div>
            <RelatedPosts 
            relatedposts={relatedposts} 
            thispostid={post.id}
            thispath={breadcrumb2link}
            ></RelatedPosts>
          </div>
          

          <div className={styles.keywordwrapper}>
            <div className={styles.keyword}>キーワードで探す</div>
            <TagsList istop={false}></TagsList>
          </div>

          <div className = {styles.nextpostnavseparate}>
                <div className = {styles.netpostnavseparatetitle}>
                </div>
                <hr />
          </div>

          <div className = {styles.postnav}>
            <nav className="blog-post-nav">
              <ul className={styles.postnavwrapper}>

                <li className={styles.previouslinkwrapper}>
                  {previous && (
                    <a className = {styles.nextlink}
                      href={previousslug} 
                      rel="prev"
                      style={LinkStyle}
                      
                    >
                      <div className = {styles.nexteyecatchwrapper}>
                        <GatsbyImage className = {styles.eyecatch}
                          image = {previouseyecatch}
                          alt = "previous post eyecatch img"
                        />
                      </div>
                      <div className={styles.nexttitle}>← {previous.frontmatter.title}</div>
                      
                    </a>
                  )}
                </li>

                <li className = {styles.nextlinkwrapper}>
                  {next && (
                    <a className = {styles.nextlink}
                      href={nextslug} 
                      rel="next"
                      style={LinkStyle}
                      
                    >
                      <div className = {styles.nexteyecatchwrapper}>
                        <GatsbyImage className = {styles.eyecatch}
                          image = {nexteyecatch}
                          alt = "next post eyecatch img"
                        />
                      </div>
                      <div className={styles.nexttitle}>{next.frontmatter.title} →</div>
                    </a>
                  )}
                </li>

              </ul>
            </nav>
          </div>

          {/* <Script type="text/javascript" src="//image.moshimo.com/static/publish/af/rakuten/widget.js"/> */}

          

        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $relatedcategory: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      tableOfContents
      fields{
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        category1
        category2
        hero{
          childImageSharp{
            gatsbyImageData(
              width: 1024
              placeholder: NONE
            )
            fluid{
              src
            }
          }
        }
        description
        embeddedImagesLocalL {
          childImageSharp{
            gatsbyImageData(
              width: 1024
              placeholder: NONE
            )
          }
        }
        embeddedImagesLocalP {
          childImageSharp{
            gatsbyImageData(
              height: 1024
              aspectRatio: 0.666666
              placeholder: NONE
            )
          }
        }
      }
    }
    relatedposts: allMdx(filter: {frontmatter: {category2: {eq: $relatedcategory}}}) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          hero{
            childImageSharp{
              gatsbyImageData(
                placeholder: NONE
              )
            }
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        category1
        category2
        title
        hero{
          childImageSharp{
            gatsbyImageData(
              width: 200
              placeholder: NONE
            )
          }
        }
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        category1
        category2
        title
        hero{
          childImageSharp{
            gatsbyImageData(
              width: 200
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`
